<template>
  <div>

    <!-- modal -->
    <b-modal
      id="modal-primary"
      :ok-only="false"
      ok-title="บันทึกข้อมูล"
      cancel-title="ยกเลิก"
      modal-class="modal-primary"
      :title="_title"
      size="xs"
      @ok="clickSave"
      @cancel="clickCancel"
    >
      <b-card-text>
        <validation-observer ref="formp">
          <b-form>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  label="ชื่อ"
                  label-for="a-first_name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="first_name"
                    rules="required"
                  >
                    <b-form-input
                      id="a-first_name"
                      v-model="form.user_profile_data.first_name"
                      :state="errors.length > 0 ? false : null"
                      type="text"
                      placeholder="กรอก ชื่อ"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- price -->
              <b-col cols="12">
                <b-form-group
                  label="สกุล"
                  label-for="a-last_name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="last_name"
                    rules="required"
                  >
                    <b-form-input
                      id="a-price"
                      v-model="form.user_profile_data.last_name"
                      :state="errors.length > 0 ? false : null"
                      type="text"
                      placeholder="กรอก สกุล"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group
                  label="email"
                  label-for="a-email"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="a-email"
                      v-model="form.email"
                      :state="errors.length > 0 ? false : null"
                      type="email"
                      placeholder="กรอก อีเมล"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group
                  label="password"
                  label-for="a-password"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="password"
                    :rules="form.id ? '' : 'required'"
                  >
                    <b-form-input
                      id="a-password"
                      v-model="form.password"
                      :state="errors.length > 0 ? false : null"
                      type="password"
                      placeholder="กรอก รหัสผ่าน"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import { BButton, BModal, VBModal } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, numeric, double, email } from '@validations'
export default {
  props: {
    role: String,
  },
  data() {
    return {
      required,
      numeric,
      double,
      email,
      form: {
        id: '',
        name: '',
        email: '',
        password: '',
        password_confirmation: '',
        user_profile_data: {
          first_name: '',
          last_name: '',
        },
      },
    }
  },
  computed: {
    _title() {
      return `${this.form?.id ? 'แก้ไข' : 'เพิ่ม'} ข้อมูล ${this.role?.title}`
    },
  },
  components: {
    BButton,
    BModal,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  methods: {
    show(data = '') {
      if (data) this.form = data
      this.$bvModal.show('modal-primary')
    },
    async clickSave(data) {
      data.preventDefault()
      const form = this.form

      switch (this.role) {
        case 'User':
          form.role_data_id = 'c6e63c72-aeda-4caf-aadb-5dba9f1b91fc'
          break
        case 'Partner':
          form.role_data_id = '974f843e-8b0e-4221-8bc5-767276a9f98e'
          break
        case 'Support':
          form.role_data_id = '6e5edbb0-f750-4c81-9669-81147585789f'
          break
        case 'Admin':
          form.role_data_id = '7fa970cc-aa32-49bc-9cf6-50f996bc6385'
          break

        default:
          break
      }
      // const validate = await this.$refs.formp.validate()
      // if (!validate) return ''

      const resp = await this.api.createOrUpdate('/api/admin/users', this.form, true)

      Object.keys(this.form).forEach(field => (this.form[field] = ''))
      this.$bvModal.hide('modal-primary')
      this.$emit('reload', '')
    },
    clickCancel() {},
  },
}
</script>
