var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"id":"modal-primary","ok-only":false,"ok-title":"บันทึกข้อมูล","cancel-title":"ยกเลิก","modal-class":"modal-primary","title":_vm._title,"size":"xs"},on:{"ok":_vm.clickSave,"cancel":_vm.clickCancel}},[_c('b-card-text',[_c('validation-observer',{ref:"formp"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"ชื่อ","label-for":"a-first_name"}},[_c('validation-provider',{attrs:{"name":"first_name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"a-first_name","state":errors.length > 0 ? false : null,"type":"text","placeholder":"กรอก ชื่อ"},model:{value:(_vm.form.user_profile_data.first_name),callback:function ($$v) {_vm.$set(_vm.form.user_profile_data, "first_name", $$v)},expression:"form.user_profile_data.first_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"สกุล","label-for":"a-last_name"}},[_c('validation-provider',{attrs:{"name":"last_name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"a-price","state":errors.length > 0 ? false : null,"type":"text","placeholder":"กรอก สกุล"},model:{value:(_vm.form.user_profile_data.last_name),callback:function ($$v) {_vm.$set(_vm.form.user_profile_data, "last_name", $$v)},expression:"form.user_profile_data.last_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"email","label-for":"a-email"}},[_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"a-email","state":errors.length > 0 ? false : null,"type":"email","placeholder":"กรอก อีเมล"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"password","label-for":"a-password"}},[_c('validation-provider',{attrs:{"name":"password","rules":_vm.form.id ? '' : 'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"a-password","state":errors.length > 0 ? false : null,"type":"password","placeholder":"กรอก รหัสผ่าน"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }